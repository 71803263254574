$(document).ready(function () {
  $(document).scroll(function () {
    let scrollTop = $(this).scrollTop();
    let rotation = scrollTop / 5; // Adjust the divisor to change the speed of rotation
    $(".flower").css("transform", "rotate(" + rotation + "deg)");
  });
});

document.addEventListener("DOMContentLoaded", function () {
  const filterLinks = document.querySelectorAll(".selectors .custom-hover");
  const projectItems = document.querySelectorAll(".projects li");

  filterLinks.forEach(function (link) {
    link.addEventListener("click", function () {
      const filterValue = this.getAttribute("data-filter");

      filterLinks.forEach(function (link) {
        link.classList.remove("active", "underline");
      });

      this.classList.add("active", "underline");

      projectItems.forEach(function (item) {
        const itemCategory = item.getAttribute("data-category");
        if (filterValue === "all" || itemCategory === filterValue) {
          item.classList.remove("is-hidden");
          // item.style.display = "flex";
        } else {
          item.classList.add("is-hidden");
          // item.style.display = "none";
        }
      });

      setJobsAlign();
    });
  });

  //   const customCursor = document.querySelector(".custom-img");

  //   document.addEventListener("mousemove", function (e) {
  //     // Update the position of the custom cursor based on the mouse coordinates
  //     const x = e.clientX;
  //     const y = e.clientY;
  //     customCursor.style.left = `${x}px`;
  //     customCursor.style.top = `${y}px`;
  //   });

  // // Gestion des filtres
  // var filters = document.querySelectorAll(".filter");

  // filters.forEach(function (filter) {
  //   filter.addEventListener("click", function (e) {
  //     e.preventDefault(); // Désactivation de l'action par défaut du lien
  //     var filter = e.currentTarget.getAttribute("data-filter"); // Récupération de la valeur du filtre

  //     filterJobs(filter); // Filtrage des jobs selon la valeur du filtre
  //     setJobsAlign(); // Alignement des jobs visibles
  //   });
  // });

  // // Filtrage des jobs
  // function filterJobs(jobClass) {
  //   var jobs = document.querySelectorAll(".job");

  //   jobs.forEach(function (job) {
  //     if (job.classList.contains(jobClass) || !jobClass) {
  //       if (job.classList.contains("is-hidden")) {
  //         job.classList.remove("is-hidden");
  //       }
  //     } else {
  //       job.classList.add("is-hidden");
  //     }
  //   });
  // }

  // Alignement des jobs
  function setJobsAlign() {
    var counter = 0;
    var visibleJobs = document.querySelectorAll(".job:not(.is-hidden)");

    visibleJobs.forEach(function (job) {
      counter++;

      if (counter % 2) {
        job.classList.remove("align-right");
        job.classList.add("align-left");
      } else {
        job.classList.remove("align-left");
        job.classList.add("align-right");
      }
    });
  }

  setJobsAlign();
});
